import * as React from 'react'

import { graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

// Components
import Benefits from '../components/Benefits'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SearchCard from '../components/SearchCard'
import Cta from '../components/Cta'
import Columns from '../components/Columns'
import Carousel from '../components/Carousel'
import { Box, Chip, Container, Stack, Toolbar, Typography } from '@mui/material'

// Styling
import '../styles/search.scss'

// Images
import LoadingGif from '../assets/images/loading.svg'
import ApparelIcon from '../assets/images/svg/apparel.inline.svg'
import FabricIcon from '../assets/images/svg/fabric.inline.svg'
import YarnsIcon from '../assets/images/svg/yarns.inline.svg'
import TrimsIcon from '../assets/images/svg/trims.inline.svg'
import HomeTextilesIcon from '../assets/images/svg/home-textiles.inline.svg'
import NotFound from '../assets/images/svg/not-found.inline.svg'

//Content
import { ctaContent } from '../content/cta'
import { pakistanHubContent } from '../content/pakistan-hub'
import { logoSlideContent } from '../content/logoSlide'

const api = process.env.API
const HUB_ID = 8

const products = {
  apparel: { icon: <ApparelIcon />, color: 'silkyGreen', label: 'Aparel', defaultId: 1, newCategory: false },
  fabrics: { icon: <FabricIcon />, color: 'indigo', label: 'Fabrics', defaultId: 2, newCategory: false },
  trims: { icon: <TrimsIcon />, color: 'darkBlue', label: 'Trims', defaultId: 3, newCategory: false },
  yarns: { icon: <YarnsIcon />, color: 'tourquise', label: 'Yarns', defaultId: 4, newCategory: false },
  hometextiles: {
    icon: <HomeTextilesIcon />,
    color: 'mysticIris',
    label: 'Home Textiles',
    defaultId: 5,
    newCategory: true,
  },
}

const MiniChip = styled(Chip)(({ theme }) => ({
  '&&': {
    height: 24,
    '&:hover': {
      backgroundColor: theme.palette.silkyGreen.main,
    },
    '.MuiChip-label': {
      fontSize: '12px',
      color: '#ffffff',
    },
  },
}))

const newCategoryProps = {
  deleteIcon: <MiniChip label='New' color='silkyGreen' size='small' />,
  onDelete: () => () => {},
}

class PakistanHubPage extends React.Component {
  constructor(props) {
    super(props)
    this.t = props.t
    const urlType = new URLSearchParams(this.props.location.search).get('type')

    this.state = {
      results: [],
      productCategories: [],
      type: products[urlType]?.defaultId ?? null,
      page: 1,
      showMore: true,
      loading: true,
      loadingBtn: false,
    }
  }

  componentDidMount() {
    this.fetchResults(this.state.page)
  }

  render() {
    return (
      <Layout headerWhite={true}>
        <Toolbar />
        <Columns content={pakistanHubContent(this.t).columnsCover} />
        <Container disableGutters maxWidth='xl' sx={{ textAlign: 'center' }}>
          <Typography variant='h3' sx={{ my: 2 }}>
            {this.t('PAKISTAN_HUB_SUPPLIERS')}
          </Typography>

          {this.state.results?.length > 0 && (
            <Box className='filters-container' sx={{ my: 5 }}>
              <Stack direction='row' justifyContent='center' sx={{ flexWrap: 'wrap', gap: 1, width: '100%', my: 2 }}>
                {this.state.productCategories?.map((key, id) => {
                  const productCategory = key.value.replace('-', '')
                  return (
                    <Chip
                      key={id}
                      icon={products[productCategory]?.icon}
                      id={productCategory + '-chip'}
                      className={'chip-' + productCategory + ' ' + (key.id !== this.state.type ? 'disabled' : 'active')}
                      label={key.name}
                      color={products[productCategory].color}
                      onClick={() => {
                        this.changeType(key.id)
                      }}
                      sx={{ order: products[productCategory]?.defaultId }}
                      {...(products[productCategory]?.newCategory && newCategoryProps)}
                    />
                  )
                })}
              </Stack>
            </Box>
          )}
        </Container>
        <Container disableGutters maxWidth='xl' sx={{ textAlign: 'center' }} className='search-container'>
          {this.state.loading ? (
            <Box>
              <img src={LoadingGif} height={100} alt={'Loading...'} />
            </Box>
          ) : (
            <>
              {this.state.results?.length ? (
                <>
                  <Box className='row gx-4 gy-4'>
                    {this.state.results?.map((infoCard, i) => (
                      <Box key={i} className='col-13 col-sm-6 col-md-4 col-xl-3'>
                        <SearchCard info={infoCard} type={this.state.type} />
                      </Box>
                    ))}
                  </Box>
                  {this.state.showMore && (
                    <Box sx={{ my: { xs: 6, md: 8 } }}>
                      <button
                        className='btn btn-primary btn-md'
                        onClick={() => {
                          this.showMore()
                        }}
                        disabled={this.state.loadingBtn}
                      >
                        {this.state.loadingBtn ? this.t('SEARCH_LOADING') : this.t('SEARCH_SHOW_MORE')}
                      </button>
                    </Box>
                  )}
                </>
              ) : (
                <Box sx={{ py: 2 }}>
                  <Typography>There are no matching results!</Typography>
                  <NotFound />
                </Box>
              )}
            </>
          )}
        </Container>
        <Box className='full-div' sx={{ my: { xs: 6, md: 8 }, py: { xs: 6, md: 8 } }}>
          <Benefits mode={'light'} turkey={true} />
        </Box>
        <Carousel content={logoSlideContent('big', 'default', true, true).carousel} />
        <Cta content={ctaContent} />
      </Layout>
    )
  }

  showMore = () => {
    this.setState({ page: this.state.page + 1, loadingBtn: true }, function () {
      this.fetchResults(this.state.page)
    })
  }

  changeType = (newType) => {
    this.setState(
      { page: 1, results: [], type: this.state.type === newType ? null : newType, loading: true },
      function () {
        this.fetchResults()
      }
    )
  }

  fetchResults = (pageNumber) => {
    let headers = new Headers()
    headers.set('Domain', 'public')

    fetch(api + `search/entities?page=${pageNumber ?? 1}&limit=32&generic=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        { key: 'hubs', selected: [HUB_ID] },
        { key: 'product_category', selected: this.state.type ? [this.state.type] : [] },
      ]),
    })
      .then((response) => response.json())
      .then((resultData) => {
        let currentArray = [...this.state.results]

        resultData?.data?.results?.forEach((result) => {
          currentArray.push(result)
        })

        this.setState({
          results: currentArray,
          showMore: resultData?.links?.next ? true : false,
          loading: false,
          loadingBtn: false,
          productCategories: resultData?.data?.facets?.find((obj) => obj.key === 'product_category')['values'],
        })
      })
  }
}

export const Head = ({ location }) => (
  <Seo
    title='Pakistan Suppliers'
    description="Pakistan's textile market stands out for its large supply base for almost all man-made and natural yarns and fabrics, including cotton, rayon, and others. Discover suppliers from Pakistan inside Foursource’s exclusive and curated sourcing hub, Emerging Pakistan."
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withTranslation()(PakistanHubPage)
